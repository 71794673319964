import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IProduct } from '../models/IProduct';
import axios from '../../axios';

export interface ProductState {
  product: IProduct;
}

export const fetchAllProduct: any = createAsyncThunk<any>(
  'product/fetchProduct',
  async (categoryCurrent: any) => {
    const category = categoryCurrent && `&category=${categoryCurrent}`

    const { data }: any = await axios.get(`/product?admin=true${category}`);
    return data;
  },
);

export const fetchRemoveProduct: any = createAsyncThunk<any>(
  'product/fetchRemoveProduct',
  async (id) => {
    axios.delete(`/product/${id}`);
  },
);

const initialState: ProductState = {
  product: {
    items: [],
    totalPage: 0,
    status: 'loading',
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: {
    // Получение товаров
    [fetchAllProduct.pending]: (state) => {
      state.product.items = [];
      state.product.status = 'loading';
    },
    [fetchAllProduct.fulfilled]: (state, action) => {
      state.product.items = action.payload.data;
      state.product.totalPage = action.payload.totalPage;
      state.product.status = 'loaded';
    },
    [fetchAllProduct.rejected]: (state) => {
      state.product.items = [];
      state.product.status = 'error';
    },
    // Удаление товара
    [fetchRemoveProduct.pending]: (state, action) => {
      state.product.items = state.product.items
        && state.product.items.filter((items) => items.id !== action.meta.arg);
    },
  },
});

export default productSlice.reducer;
