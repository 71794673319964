import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {
  ProductState,
  fetchAllProduct,
} from '../redux/slices/product';
import { IProductData } from '../redux/models/IProduct';
import Product from '../components/product';

export default function Home() {
  const dispatch = useDispatch();

  const { product }: any = useSelector((state: ProductState) => state.product);
  const isDressesLoading = product.status === 'loading';

  useEffect(() => {
    dispatch(fetchAllProduct(''));
  }, [dispatch]);


  if(isDressesLoading) {
    return <span>Загрузка...</span>
  }

  return (
    <>
      <Box display="flex">
        <Button
          component={Link}
          color="secondary"
          to="/add-product"
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Добавить товар
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell>Id</TableCell>
              <TableCell align="left">Название</TableCell>
              <TableCell align="left">Категория</TableCell>
              <TableCell align="left">Сортировка</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {product?.items.map((item: IProductData) => (
              <Product key={item.id} data={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
