import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Checkbox, FormControlLabel, ListItemText } from '@mui/material';
import axios from '../../axios';
import styles from './addProducts.module.scss';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor'

export default function AddProduct() {
  const { id } = useParams();
  const isEdited = Boolean(id);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [preview, setPreview] = useState<string[]>([]);
  const [gallery, setGallery] = useState<string[]>([]);
  const [category, setCategory] = useState<string[]>([]);
  const [sort, setSort] = useState<number>();
  const [published, setPublished] = useState<boolean>(false);
  const [youtube, setYoutube] = useState('');
  const inputImage = useRef<HTMLInputElement>(null);
  const inputImageGallery = useRef<HTMLInputElement>(null);

  const handleChangeFile = async (currentPathFile:string, event: any) => {
    try {
      const formData = new FormData();
      const file = event?.target.files;

      for (let i = 0; i < file.length; i += 1) {
        formData.append('images', file[i]);
      }
      const { data } = await axios.post(
        '/upload',
        formData,
        { params: { id: `${id}`, currentPathFile: `${currentPathFile}` } },
      );
      if(currentPathFile === 'preview') {
        if(preview.length > 0) {
          console.log(preview)
          return setPreview(prev => [...prev, data.urls.join()]);
        }
        return setPreview(data.urls);
      } else {
        if(gallery.length > 0) {
          return setGallery(gallery.concat(data.urls));
        }
        return setGallery(data.urls);
      }

    } catch (err) {
      console.log(err);
    }
  };

  const onClickRemoveImage = async (event: any) => {
    try {
    const nameImage = event.target.nextSibling.dataset.name;
    const deleteImage = preview.filter((e) => e !== nameImage);
    const deleteImageGallery = gallery.filter((e) => e !== nameImage);

    await axios.post(`/removeFile`, {currentPathFile: nameImage})
    setPreview(deleteImage);
    setGallery(deleteImageGallery);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      axios.get(`/product/${id}`).then((data: any) => {
        setTitle(data.data.title);
        setCategory(data.data.category);
        setSort(data.data.sort);
        setDescription(data.data.description);
        setPreview(data.data.preview);
        setPublished(data.data.published);
        setGallery(data.data.gallery);
        setYoutube(data.data.youtube)
      });
    }
  }, [id]);

  const names = [
    'Система рециркуляции абразива',
    'Оборудование для наклепа',
    'Камера работающая под давлением',
    'Инжекционное оборудование',
    'Инжекционная камера',
    'Дробеструйное оборудование с замкнутым контуром',
    'Дробеструйная установка контейнерного типа',
    'Дробеструйная камера',
    'Дробеструйная бочка',
    'Возврат абразива Пневматический насос',
    'Сетчато-ленточное оборудование для дробеструйной обработки',
    'Роликовый  конвейер дробеметного оборудования',
    'Однодверная Дробеметная камера с подвеской',
    'Монорельсовое дробеструйное оборудование',
    'Линия для очистки листа',
    'Ленточная установка непрерывного действия',
    'Дробеметное оборудование с ремнем',
    'Дробеметное оборудование для очистки труб',
    'Дробеметная установка барабанного типа',
    'Двухдверная дробеметная камера с подвеской',
    'Двойные камеры с подвеской',
    'Барабанные диски для дробеметной установки',
    'Турбина',
    'Поворотная головка',
    'ФВУ CP',
    'ФВУ BMF-H',
    'Коллектор сварочного дыма',
  ];

  const handleChange = (event: SelectChangeEvent<typeof category>) => {
    const {
      target: { value },
    } = event;
    setCategory(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeCheckBox = (event: any) => {
    setPublished(event.target.checked);
  };

  const navigate = useNavigate();
  const onsubmit = async () => {
    try {
      const fields = {
        title,
        category,
        description,
        sort,
        preview,
        published,
        gallery,
        youtube,
      };
      const { data } = isEdited
        ? await axios.patch(`/product/${id}`, fields)
        : await axios.post('/product', fields);
      const idDress = isEdited ? id : data.id;
      const url = isEdited ? '/' : `/product/${idDress}/edit`;
      navigate(`${url}`);
    } catch (err) {
      console.log('Не удалось отправить');
    }
  };

  return (
    <>
        {isEdited && (
          <>
          <div>
            <div className={styles.imageContainer}>
              {preview
                && preview.map((el) => (
                  <div className={styles.imageItem} key={el}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={onClickRemoveImage}
                      className={styles.imageDelete}
                    >
                      Удалить
                    </Button>
                    <img
                      data-name={el}
                      className={styles.image}
                      src={`https://api.cmvrus.ru/images/${el}`}
                      alt="Uploaded"
                    />
                  </div>
                ))}
            </div>
            <div className={styles.Item}>
              <Button
                onClick={() => inputImage.current?.click()}
                variant="outlined"
                size="large"
              >
                Загрузить превью
              </Button>
              <input
                ref={inputImage}
                multiple
                type="file"
                onChange={(e) => handleChangeFile('preview', e)}
                hidden
              />
            </div>
          </div>
            <div>
              <div className={styles.imageContainer}>
                {gallery
                  && gallery.map((el) => (
                    <div className={styles.imageItem} key={el}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={onClickRemoveImage}
                        className={styles.imageDelete}
                      >
                        Удалить
                      </Button>
                      <img
                        data-name={el}
                        className={styles.image}
                        src={`https://api.cmvrus.ru/images/${el}`}
                        alt="Uploaded"
                      />
                    </div>
                  ))}
              </div>
              <div className={styles.Item}>
                <Button
                  onClick={() => inputImageGallery.current?.click()}
                  variant="outlined"
                  size="large"
                >
                  Загрузить фото в галерею
                </Button>
                <input
                  ref={inputImageGallery}
                  multiple
                  type="file"
                  onChange={(e) => handleChangeFile('gallery', e)}
                  hidden
                />
              </div>
            </div>
          </>
        )}
        <TextField
          classes={{ root: styles.title }}
          variant="standard"
          placeholder="Название товара..."
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className={styles.select}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">
              Выберите категорию
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              multiple
              value={category}
              onChange={handleChange}
              input={<OutlinedInput label="Категории" />}
              renderValue={(selected) => {
                if (selected.length < 1) {
                  return <em>Выберите категорию</em>;
                }
                return selected.join(', ');
              }}
            >
              {names.map((name, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={`${name} - ${index}`} value={name}>
                  <Checkbox checked={category.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <TextField
          classes={{ root: styles.tags }}
          variant="standard"
          placeholder="Выбранные категории"
          fullWidth
          value={category}
          disabled
          style={{display: 'none'}}
        />
        <div style={{margin: '40px 0 40px 0'}}>
          <CKEditor
              editor={Editor}
              data={description}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setDescription(data);
              }}
          />
        </div>
      <TextField
          classes={{ root: styles.tags }}
          variant="standard"
          placeholder="Ссылка на youtube"
          fullWidth
          type="string"
          value={youtube}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setYoutube(e.target.value)}
      />
        <TextField
          classes={{ root: styles.tags }}
          variant="standard"
          placeholder="Сортировка"
          fullWidth
          type="number"
          value={sort}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setSort(parseInt(e.target.value, 10))}
        />
        <div className={styles.Item}>
          <FormControlLabel
            control={
              <Checkbox checked={published} onChange={handleChangeCheckBox} />
            }
            label="Опубликовать?"
          />
        </div>
        <div className={styles.buttons}>
          <Button onClick={onsubmit} size="large" variant="contained">
            {isEdited ? 'Сохранить' : 'Опубликовать'}
          </Button>
          <a href="/">
            <Button size="large">Отмена</Button>
          </a>
        </div>
    </>
  );
}
