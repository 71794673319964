import { configureStore } from '@reduxjs/toolkit';
import productSlice from './slices/product';
import authSlice from './slices/auth';

const store = configureStore({
  reducer: {
    product: productSlice,
    auth: authSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
