import React from 'react';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useDispatch } from 'react-redux';
import { IProductData } from '../../redux/models/IProduct';
import { fetchRemoveProduct } from '../../redux/slices/product';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from '../../axios';

interface Props {
  data: IProductData;
}

export default function Product({ data }: Props) {
  const dispatch = useDispatch();
  const onClickRemove = async () => {
    if (window.confirm('Вы действительно хотите удалить товар?')) {
      try {
        dispatch(fetchRemoveProduct(data.id));
        await axios.post(`/removeFile`, {}, { params: { id: `${data.id}` } },);
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <TableRow
      key={data.title}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="left">{data.published ? <VisibilityIcon color={'primary'} /> : <VisibilityOffIcon color={'error'} />}</TableCell>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell align="left">{data.title}</TableCell>
      <TableCell align="left">
        {data.category && data.category.join()}
      </TableCell>
      <TableCell align="left">{data.sort}</TableCell>

      <TableCell align="left">
        <Link to={`/product/${data.id}/edit`}>
          <ModeEditIcon  color={'primary'}  />
        </Link>
      </TableCell>
      <TableCell align="left">
        <HighlightOffIcon onClick={onClickRemove} color={'error'} />
      </TableCell>
    </TableRow>
  );
}
