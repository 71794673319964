import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from './pages/Home';
import AddProduct from './pages/AddProduct';
import Login from './pages/Login';
import { fetchAuthMe, selectIsAuth } from './redux/slices/auth';
import Layout from './components/layout';
function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const { status } = useSelector((state: any) => state.auth);

  const isisAuthLoading = status === 'loading';

  useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  if (isisAuthLoading) {
    return (
      <div className="loader-container">
        <div className="spinner" />
      </div>
    );
  }

  if (!isAuth) {
    return <Login />;
  }

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:id/edit" element={<AddProduct />} />
          <Route path="/login" element={<Login />} />
          <Route path="/add-product" element={<AddProduct />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
